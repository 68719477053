import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SectionLoader from "../../helpers/SectionLoader";
import { lazy } from "react";
import { Suspense } from "react";
import OfflinePage from "../../includes/OfflinePage";
import Privacy from "../../Privacy";
import TermsAndConditions from "../../TermsAndConditions";


const Footer = lazy(() => import("../../includes/Footer"));
const Header = lazy(() => import("../../includes/Header"));
const HomeLandingPage = lazy(() => import("../../screens/home/HomeLandingPage"));
const AboutLandingPage = lazy(() => import("../../screens/about/AboutLandingPage"));
const ServicesLandingPage = lazy(() => import("../../screens/services/ServicesLandingPage"));
const BlogLandingPage = lazy(() => import("../../screens/blog/BlogLandingPage"));
const BlogSinglePage = lazy(() => import("../../screens/blog/BlogSinglePage"));
const Testimonials = lazy(() => import("../../screens/Testimonials"));
const ContactUs = lazy(() => import("../../screens/ContactUs"));

function AppRouter() {
    const location = useLocation();
    const hideHeaderFooterRoutes = ["/privacy", "/offline","/terms-and-condition"];
    const shouldRenderHeaderFooter = !hideHeaderFooterRoutes.includes(location.pathname);

    return (
        <div>
            {shouldRenderHeaderFooter && <Header />}
            <Suspense fallback={<SectionLoader />}>
                <Routes>
                    <Route path="/" element={<HomeLandingPage />} />
                    <Route path="/about" element={<AboutLandingPage />} />
                    <Route path="/services" element={<ServicesLandingPage />} />
                    <Route path="/blog" element={<BlogLandingPage />} />
                    <Route path="/blog/:id" element={<BlogSinglePage />} />
                    <Route path="/testimonials" element={<Testimonials />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/offline" element={<OfflinePage />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-and-condition" element={<TermsAndConditions />} />

                </Routes>
            </Suspense>
            {shouldRenderHeaderFooter && <Footer />}
        </div>
    );
}

export default AppRouter;
