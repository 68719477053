import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <MainContainer>
                <WrapperContainer className="wrapper">
                    <RightContainer>
                        <h1>
                            <a>
                                <LogoBox to="/">
                                    <Logo
                                        src={require("./assets/images/main-logo.svg").default}
                                        alt="logo"
                                    />
                                </LogoBox>
                            </a>
                        </h1>
                    </RightContainer>
                    <ContentBox>
                        <Heading>Terms and Conditions </Heading>                
                        <BottomBox>
                        <InfoBox>
    <SubHeading>1. Introduction</SubHeading>
    <SubPara>
        1.1. UK Xpress, referred to as "we," "us," or "our," is a platform dedicated to providing comprehensive guidance and support to students aspiring to pursue their education abroad. These Terms and Conditions (referred to as "Agreement") govern your use of the UK Xpress website and services.
    </SubPara>
    <SubPara>
        1.2. By accessing and using our services, you, referred to as "you" or "user," acknowledge that you have read, understood, and agreed to abide by this Agreement. If you do not agree with these terms, please refrain from using our services.
    </SubPara>
    <SubPara>
        1.3. UK Xpress reserves the right to modify, add, substitute, or terminate any services at our discretion. Any such modifications will be reflected in these Terms and Conditions, and it is your responsibility to stay informed about these changes.
    </SubPara>
    <SubPara>
        1.4. It is your responsibility to regularly review this Agreement and the Privacy Policy. By continuing to use our services after any modifications, you agree to the updated terms.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>2. Services</SubHeading>
    <SubPara>
        2.1. UK Xpress offers a range of services to support students in their pursuit of international education, including but not limited to:
    </SubPara>
    <MainList>
        <SubList>● Career Counselling: Personalized guidance to help you make informed decisions about your academic future, aligning your goals with your aspirations.</SubList>
        <SubList>● Course Search: Access to a comprehensive database of academic programs, assisting you in finding the most suitable courses based on your preferences.</SubList>
        <SubList>● Admission Process: Step-by-step assistance in preparing and submitting applications to educational institutions, ensuring all necessary documents are in order.</SubList>
        <SubList>● Visa Processing: Expert guidance through the complex visa application process, helping you secure the necessary visas for your study destination.</SubList>
        <SubList>● Interview Preparation: Support in preparing for admission interviews, boosting your confidence and effectiveness during the interview process.</SubList>
        <SubList>● Post Arrival Services: Assistance with accommodation, orientation, and ongoing support to ensure a smooth transition to studying abroad.</SubList>
        <SubList>● Language Training: Tailored training programs to improve your language skills and meet the language requirements for your desired courses.</SubList>
    </MainList>
</InfoBox>

<InfoBox>
    <SubHeading>3. Eligibility</SubHeading>
    <SubPara>
        3.1. You must be of legal age to register on the UK Xpress platform and access our services. If you are under the age of 18, you must have the consent of your legal guardian to use our services.
    </SubPara>
    <SubPara>
        3.2. If you are registering on behalf of an individual or entity other than yourself, you represent that you have the authority to accept this Agreement on their behalf.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>4. Compliance with Law</SubHeading>
    <SubPara>
        4.1. This Agreement complies with all relevant laws, rules, and regulations, including the Indian Contract Act, 1872, Information Technology Act, 2000, Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and Information Technology (Intermediaries Guidelines) Rules, 2011.
    </SubPara>
</InfoBox>
<InfoBox>
    <SubHeading>5. Definitions</SubHeading>
    <SubPara>
        5.1. "Applicable Law" refers to any domestic or foreign law, rule, statute, regulation, or other legal requirement that applies to or regulates the use of our services.
    </SubPara>
    <SubPara>
        5.2. "User" refers to individuals, institutions, organizations, or entities registered with UK Xpress.
    </SubPara>
    <SubPara>
        5.3. "Services" encompass all the services provided on the UK Xpress platform, subject to change at our discretion.
    </SubPara>
    <SubPara>
        5.4. "Our Content" includes text, data, graphics, images, and other materials provided through our services.
    </SubPara>
    <SubPara>
        5.5. "User Content" includes the content posted by users on the UK Xpress platform.
    </SubPara>
    <SubPara>
        5.6. "Posted Information" refers to all content, inquiries, and details posted by users on the platform.
    </SubPara>
    <SubPara>
        5.7. "You" refers to the user as defined above.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>6. Privacy Policy</SubHeading>
    <SubPara>
        6.1. By registering, you agree to provide certain personal information and authorize UK Xpress to use and disclose this information in accordance with relevant laws and our Privacy Policy.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>7. Relationship</SubHeading>
    <SubPara>
        7.1. Each party operates independently, and this Agreement does not create a joint venture, partnership, principal-agent, or employment relationship.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>8. Sharing</SubHeading>
    <SubPara>
        8.1. Content shared on our platform may be visible to other users. We are not obligated to publish or retain any information on our service.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>9. Your License to UK Xpress</SubHeading>
    <SubPara>
        9.1. You retain ownership of the content and information you submit. You grant UK Xpress a non-exclusive license to use, copy, modify, and distribute this content for the purpose of providing our services.
    </SubPara>
    <SubPara>
        9.2. You agree that we may access, store, process, and use the information and data you provide in accordance with our Privacy Policy.
    </SubPara>
    <SubPara>
        9.3. You agree to provide truthful and lawful content and information, and we may be required by law to remove certain content.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>10. Responsibilities of the User</SubHeading>
    <SubPara>
        10.1. Users are responsible for adhering to our guidelines, including not posting harmful or unlawful content.
    </SubPara>
    <SubPara>
        10.2. Users should only use UK Xpress for its intended purposes.
    </SubPara>
    <SubPara>
        10.3. Users must provide accurate information.
    </SubPara>
    <SubPara>
        10.4. Unsolicited communications to other users are prohibited.
    </SubPara>
    <SubPara>
        10.5. Misuse of platform content is not allowed.
    </SubPara>
</InfoBox>
<InfoBox>
    <SubHeading>11. Intellectual Property Rights</SubHeading>
    <SubPara>
        UK Xpress reserves all intellectual property rights in our services. Trademarks and logos used on the platform are owned by their respective owners.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>12. Indemnity</SubHeading>
    <SubPara>
        12.1. Users agree to indemnify and hold UK Xpress and its affiliates harmless from any third-party claims, losses, or liabilities arising from their use of our services.
    </SubPara>
    <SubPara>
        12.2. In no event shall UK Xpress be liable for certain types of damages, and our total aggregate liability is limited.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>13. Assignment</SubHeading>
    <SubPara>
        13.1. UK Xpress may assign this contract at its discretion. Users may not assign this Agreement.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>14. Waiver</SubHeading>
    <SubPara>
        No relaxation, forbearance, or delay by UK Xpress in enforcing or exercising any terms of this Agreement shall constitute a waiver.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>15. Severability</SubHeading>
    <SubPara>
        In the event that any provision of this Agreement is declared invalid or unenforceable, the remaining provisions will remain in full force and effect.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>16. Entire Agreement</SubHeading>
    <SubPara>
        This Agreement constitutes the entire agreement between the parties and supersedes all previous agreements.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>17. Supplementary Agreement</SubHeading>
    <SubPara>
        This Agreement is to be read alongside the Privacy Policy. In case of conflict, the provisions of this Agreement prevail.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>18. Headings</SubHeading>
    <SubPara>
        The headings in this Agreement are for reference and convenience purposes and do not limit or modify the terms.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>19. Arbitration</SubHeading>
    <SubPara>
        Any disputes may be referred to arbitration, and the award of the arbitrator is final and binding.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>20. Applicable Law and Jurisdiction</SubHeading>
    <SubPara>
        This Agreement is governed by the laws of India, and the courts in Bengaluru have exclusive jurisdiction.
    </SubPara>
</InfoBox>

<InfoBox>
    <SubHeading>21. Grievance Officer</SubHeading>
    <SubPara>
        Any grievances should be addressed to UK Xpress at <a href="mailto:info@ukxpress.co.uk">info@ukxpress.co.uk</a> in writing. We will act on these grievances in accordance with applicable laws.
    </SubPara>
</InfoBox>


                            <BottomPara>
                                By using our services, you signify your acceptance of this Terms and Conditions.
                            </BottomPara>
                        </BottomBox>
                    </ContentBox>
                </WrapperContainer>
            </MainContainer>
        </>
    );
}

export default Privacy;

const MainContainer = styled.div`
    padding: 20px;
`;
const WrapperContainer = styled.div``;

const RightContainer = styled.div`
    margin-bottom: 40px;
    width: 8%;
    @media all and (max-width: 980px) {
        width: 10%;
    }
    @media all and (max-width: 640px) {
        width: 16%;
    }
    @media all and (max-width: 768px) {
        width: 14%;
    }
    @media all and (max-width: 480px) {
        width: 20%;
    }
    @media all and (max-width: 360px) {
        width: 18%;
    }
    h1 {
        width: 100%;
        line-height: 65%;
        a {
            display: inline-block;
            width: 100%;
        }
        @media all and (max-width: 1600px) {
            width: 90%;
        }
    }
`;
const LogoBox = styled(NavLink)``;
const Logo = styled.img`
    width: 100%;
    display: block;
    height: 100%;
`;
const ContentBox = styled.div``;
const Heading = styled.h1`
    font-family: "poppins_medium";
    font-size: 40px;
    margin-bottom: 40px;
`;
const Para = styled.p`
    font-family: "poppins_regular";
    font-size: 20px;
    margin-bottom: 50px;
`;
const BottomBox = styled.div``;
const InfoBox = styled.div`
    margin-bottom: 30px;
`;
const SubHeading = styled.h2`
    font-family: "poppins_medium";
    margin-bottom: 15px;
`;

const SubPara = styled.p`
    font-family: "poppins_regular";
    font-size: 20px;
    margin-bottom: 20px;
`;
const MainList = styled.ul``;
const SubList = styled.li`
    margin-bottom: 15px;
    font-size: 18px;
`;
const BottomPara = styled.p``;
