import React from "react";
import styled from "styled-components";
import OfflineLoader from "../helpers/OfflineLoader";

function OfflinePage() {
    return (
        <MainContainer>
            <OfflineLoader />
            <OfflineBox>
                <Heading>You are offline</Heading>
                <Para>
                    It seems there is a problem with your connection. Please check your network
                    status.
                </Para>
            </OfflineBox>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 150px 0px;
`;
const OfflineBox = styled.div`
    text-align: center;
    width: 80%;
    margin: 0 auto;
`;
const Heading = styled.h2`
    color: #1f3347;
    font-family: "poppins_semibold";
    margin-bottom: 20px;
`;
const Para = styled.p`
    width: 45%;
    margin: 0 auto;
`;

export default OfflinePage;
